/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
/* z indicies */
/************/
/************/
/************/
#loading-svg-container {
  position: absolute;
  left: 0;
  top: 0;
}
#main-content {
  display: block;
}
html,
body,
#main-content {
  height: 100%;
  font-size: 14px;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  background-color: MISSING;
  user-select: none;
}
html,
body,
#main-content {
  margin: 0;
  padding: 0;
  min-height: 100%;
}
.Goban {
  position: relative;
  background-color: #dcb35c;
  display: inline-block;
  themed: box-shadow goban-shadow;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.Goban .GobanMessage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: large;
  text-align: center;
  z-index: 40;
}
.Goban .PenLayer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 30;
}
.Goban .StoneLayer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;
}
.Goban .ShadowLayer {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
}
.Goban .GobanMessage table {
  position: relative;
  width: 100%;
  height: 100%;
}
.Goban .GobanMessage table td {
/* background-color: #f00; */
  background-image: none;
}
.Goban .GobanMessage table td div {
  border-radius: 3px;
  box-shadow: 5px 5px 3px rgba(0,0,0,0.26);
  color: #f50;
  background-color: #262626;
  padding: 0.3em;
  margin: 0.4em;
}
.Goban .hidden {
  display: none;
}
.Goban.borderless {
  themed: box-shadow goban-shadow-borderless;
  width: 100%;
  text-align: center;
  margin: 0;
  border-radius: 0;
}
.Goban.small {
  font-size: small;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.26);
}
.Goban.small canvas {
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.16);
}
.bg-blue,
.bg-yellow,
.bg-green,
.bg-magenta,
.bg-red {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-blue {
  background-image: url("/1.0/backgrounds/blue.jpg");
  background-color: #01021e !important;
}
.bg-yellow {
  background-image: url("/1.0/backgrounds/yellow.jpg");
  background-color: #e98b1a !important;
}
.bg-green {
  background-image: url("/1.0/backgrounds/green.jpg");
  background-color: #5fdd39 !important;
}
.bg-magenta {
  background-image: url("/1.0/backgrounds/magenta.jpg");
  background-color: #bf1945 !important;
}
.bg-red {
  background-image: url("/1.0/backgrounds/red.jpg");
  background-color: #4d2d1e !important;
}
.flag-dark-green,
button.flag:not(.disabled):active,
.stone-button-flag:not(.disabled):active {
  background-image: svg-load("../assets/buttons/flag-dark-green.svg");
}
.chat-light-green,
button.chat:not(.disabled):hover,
.stone-button-chat:not(.disabled):hover {
  background-image: svg-load("../assets/buttons/chat-light-green.svg");
}
.check-dark-green,
button.check:not(.disabled):active,
.stone-button-check:not(.disabled):active,
.Button.check:active {
  background-image: svg-load("../assets/buttons/check-dark-green.svg");
}
.right-light-green,
.left-light-green,
.up-light-green,
button.left:not(.disabled):hover,
.stone-button-left:not(.disabled):hover,
button.right:not(.disabled):hover,
.stone-button-right:not(.disabled):hover,
button.up:not(.disabled):hover,
.stone-button-up:not(.disabled):hover,
.BackButton:hover,
.Button.right-arrow:hover,
.Button.left-arrow:hover {
  background-image: svg-load("../assets/buttons/right-light-green.svg");
}
.right-light,
.left-light,
.up-light,
button.left,
.stone-button-left,
button.right,
.stone-button-right,
button.up,
.stone-button-up,
.BackButton,
.Button.right-arrow,
.Button.left-arrow {
  background-image: svg-load("../assets/buttons/right-light.svg");
}
.check-dark {
  background-image: svg-load("../assets/buttons/check-dark.svg");
}
.check-light,
button.check,
.stone-button-check,
.Button.check {
  background-image: svg-load("../assets/buttons/check-light.svg");
}
.right-dark-green,
.left-dark-green,
.up-dark-green,
button.left:not(.disabled):active,
.stone-button-left:not(.disabled):active,
button.right:not(.disabled):active,
.stone-button-right:not(.disabled):active,
button.up:not(.disabled):active,
.stone-button-up:not(.disabled):active,
.BackButton:active,
.Button.right-arrow:active,
.Button.left-arrow:active {
  background-image: svg-load("../assets/buttons/right-dark-green.svg");
}
.x-light,
button.x,
.stone-button-x {
  background-image: svg-load("../assets/buttons/x-light.svg");
}
.x-light-green,
button.x:not(.disabled):hover,
.stone-button-x:not(.disabled):hover {
  background-image: svg-load("../assets/buttons/x-light-green.svg");
}
.x-dark {
  background-image: svg-load("../assets/buttons/x-dark.svg");
}
.check-light-green,
button.check:not(.disabled):hover,
.stone-button-check:not(.disabled):hover,
.Button.check:hover {
  background-image: svg-load("../assets/buttons/check-light-green.svg");
}
.chat-dark-green,
button.chat:not(.disabled):active,
.stone-button-chat:not(.disabled):active {
  background-image: svg-load("../assets/buttons/chat-dark-green.svg");
}
.chat-dark {
  background-image: svg-load("../assets/buttons/chat-dark.svg");
}
.flag-dark {
  background-image: svg-load("../assets/buttons/flag-dark.svg");
}
.return-light,
button.return,
.stone-button-return {
  background-image: svg-load("../assets/buttons/return-light.svg");
}
.right-dark,
.left-dark,
.up-dark {
  background-image: svg-load("../assets/buttons/right-dark.svg");
}
.chat-light,
button.chat,
.stone-button-chat {
  background-image: svg-load("../assets/buttons/chat-light.svg");
}
.x-dark-green,
button.x:not(.disabled):active,
.stone-button-x:not(.disabled):active {
  background-image: svg-load("../assets/buttons/x-dark-green.svg");
}
.flag-light-green,
button.flag:not(.disabled):hover,
.stone-button-flag:not(.disabled):hover {
  background-image: svg-load("../assets/buttons/flag-light-green.svg");
}
.return-dark-green,
button.return:not(.disabled):active,
.stone-button-return:not(.disabled):active {
  background-image: svg-load("../assets/buttons/return-dark-green.svg");
}
.return-dark {
  background-image: svg-load("../assets/buttons/return-dark.svg");
}
.return-light-green,
button.return:not(.disabled):hover,
.stone-button-return:not(.disabled):hover {
  background-image: svg-load("../assets/buttons/return-light-green.svg");
}
.flag-light,
button.flag,
.stone-button-flag {
  background-image: svg-load("../assets/buttons/flag-light.svg");
}
.left-dark {
  transform: scaleX(-1);
}
.left-light,
button.left,
.stone-button-left,
.BackButton,
.Button.left-arrow {
  transform: scaleX(-1);
}
.left-dark-green,
button.left:not(.disabled):active,
.stone-button-left:not(.disabled):active,
.BackButton:active,
.Button.left-arrow:active {
  transform: scaleX(-1);
}
.left-light-green,
button.left:not(.disabled):hover,
.stone-button-left:not(.disabled):hover,
.BackButton:hover,
.Button.left-arrow:hover {
  transform: scaleX(-1);
}
.up-dark {
  transform: rotate(-90deg);
}
.up-light,
button.up,
.stone-button-up {
  transform: rotate(-90deg);
}
.up-dark-green,
button.up:not(.disabled):active,
.stone-button-up:not(.disabled):active {
  transform: rotate(-90deg);
}
.up-light-green,
button.up:not(.disabled):hover,
.stone-button-up:not(.disabled):hover {
  transform: rotate(-90deg);
}
.red-x {
  background-image: svg-load("../assets/buttons/red-x.svg");
}
.green-check {
  background-image: svg-load("../assets/buttons/green-check.svg");
}
.refresh-light,
.stone-button-refresh {
  background-image: svg-load("../assets/buttons/refresh-light.svg");
}
.refresh-light-green,
.stone-button-refresh:not(.disabled):hover {
  background-image: svg-load("../assets/buttons/refresh-light-green.svg");
}
.stone-button,
button.check,
.stone-button-check,
button.x,
.stone-button-x,
button.flag,
.stone-button-flag,
button.chat,
.stone-button-chat,
button.return,
.stone-button-return,
button.left,
.stone-button-left,
button.right,
.stone-button-right,
button.up,
.stone-button-up,
.stone-button-refresh,
.BackButton,
.Button {
  display: inline-flex;
  border: 1px solid transparent;
  border-radius: 5rem;
  min-height: 3.7rem;
  min-width: 3.7rem;
  max-height: 3.7rem;
  max-width: 3.7rem;
  background-size: cover;
}
@media (orientation: portrait) {
  .stone-button,
  button.check,
  .stone-button-check,
  button.x,
  .stone-button-x,
  button.flag,
  .stone-button-flag,
  button.chat,
  .stone-button-chat,
  button.return,
  .stone-button-return,
  button.left,
  .stone-button-left,
  button.right,
  .stone-button-right,
  button.up,
  .stone-button-up,
  .stone-button-refresh,
  .BackButton,
  .Button {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 3rem;
    max-width: 3rem;
  }
}
.stone-button:not(.disabled),
button.check:not(.disabled),
.stone-button-check:not(.disabled),
button.x:not(.disabled),
.stone-button-x:not(.disabled),
button.flag:not(.disabled),
.stone-button-flag:not(.disabled),
button.chat:not(.disabled),
.stone-button-chat:not(.disabled),
button.return:not(.disabled),
.stone-button-return:not(.disabled),
button.left:not(.disabled),
.stone-button-left:not(.disabled),
button.right:not(.disabled),
.stone-button-right:not(.disabled),
button.up:not(.disabled),
.stone-button-up:not(.disabled),
.stone-button-refresh:not(.disabled),
.BackButton:not(.disabled),
.Button:not(.disabled) {
  cursor: pointer;
}
.stone-button.disabled,
button.check.disabled,
.stone-button-check.disabled,
button.x.disabled,
.stone-button-x.disabled,
button.flag.disabled,
.stone-button-flag.disabled,
button.chat.disabled,
.stone-button-chat.disabled,
button.return.disabled,
.stone-button-return.disabled,
button.left.disabled,
.stone-button-left.disabled,
button.right.disabled,
.stone-button-right.disabled,
button.up.disabled,
.stone-button-up.disabled,
.stone-button-refresh.disabled,
.BackButton.disabled,
.Button.disabled {
  cursor: not-allowed;
}
.stone-button.disabled,
button.check.disabled,
.stone-button-check.disabled,
button.x.disabled,
.stone-button-x.disabled,
button.flag.disabled,
.stone-button-flag.disabled,
button.chat.disabled,
.stone-button-chat.disabled,
button.return.disabled,
.stone-button-return.disabled,
button.left.disabled,
.stone-button-left.disabled,
button.right.disabled,
.stone-button-right.disabled,
button.up.disabled,
.stone-button-up.disabled,
.stone-button-refresh.disabled,
.BackButton.disabled,
.Button.disabled {
  opacity: 0.8;
}
.swal2-container {
  z-index: 10000 !important;
  opacity: 0.9;
  position: fixed;
}
.swal2-container .swal2-modal {
  opacity: 0.99;
  z-index: 10000;
}
.swal2-container .swal2-modal button {
  opacity: 0.44;
  z-index: 10000;
}
.avatar-aquatic-63 {
  background-image: url("/1.0/avatars/aquatic/63.svg");
}
.avatar-aquatic-15 {
  background-image: url("/1.0/avatars/aquatic/15.svg");
}
.avatar-aquatic-21 {
  background-image: url("/1.0/avatars/aquatic/21.svg");
}
.avatar-aquatic-35 {
  background-image: url("/1.0/avatars/aquatic/35.svg");
}
.avatar-aquatic-40 {
  background-image: url("/1.0/avatars/aquatic/40.svg");
}
.avatar-aquatic-4 {
  background-image: url("/1.0/avatars/aquatic/4.svg");
}
.avatar-aquatic-43 {
  background-image: url("/1.0/avatars/aquatic/43.svg");
}
.avatar-aquatic-27 {
  background-image: url("/1.0/avatars/aquatic/27.svg");
}
.avatar-aquatic-20 {
  background-image: url("/1.0/avatars/aquatic/20.svg");
}
.avatar-aquatic-5 {
  background-image: url("/1.0/avatars/aquatic/5.svg");
}
.avatar-aquatic-9 {
  background-image: url("/1.0/avatars/aquatic/9.svg");
}
.avatar-aquatic-60 {
  background-image: url("/1.0/avatars/aquatic/60.svg");
}
.avatar-aquatic-62 {
  background-image: url("/1.0/avatars/aquatic/62.svg");
}
.avatar-aquatic-57 {
  background-image: url("/1.0/avatars/aquatic/57.svg");
}
.avatar-aquatic-6 {
  background-image: url("/1.0/avatars/aquatic/6.svg");
}
.avatar-aquatic-17 {
  background-image: url("/1.0/avatars/aquatic/17.svg");
}
.avatar-aquatic-56 {
  background-image: url("/1.0/avatars/aquatic/56.svg");
}
.avatar-aquatic-16 {
  background-image: url("/1.0/avatars/aquatic/16.svg");
}
.avatar-aquatic-29 {
  background-image: url("/1.0/avatars/aquatic/29.svg");
}
.avatar-aquatic-18 {
  background-image: url("/1.0/avatars/aquatic/18.svg");
}
.avatar-aquatic-33 {
  background-image: url("/1.0/avatars/aquatic/33.svg");
}
.avatar-aquatic-54 {
  background-image: url("/1.0/avatars/aquatic/54.svg");
}
.avatar-aquatic-53 {
  background-image: url("/1.0/avatars/aquatic/53.svg");
}
.avatar-aquatic-46 {
  background-image: url("/1.0/avatars/aquatic/46.svg");
}
.avatar-aquatic-12 {
  background-image: url("/1.0/avatars/aquatic/12.svg");
}
.avatar-aquatic-38 {
  background-image: url("/1.0/avatars/aquatic/38.svg");
}
.avatar-aquatic-3 {
  background-image: url("/1.0/avatars/aquatic/3.svg");
}
.avatar-aquatic-24 {
  background-image: url("/1.0/avatars/aquatic/24.svg");
}
.avatar-aquatic-50 {
  background-image: url("/1.0/avatars/aquatic/50.svg");
}
.avatar-aquatic-19 {
  background-image: url("/1.0/avatars/aquatic/19.svg");
}
.avatar-aquatic-30 {
  background-image: url("/1.0/avatars/aquatic/30.svg");
}
.avatar-aquatic-31 {
  background-image: url("/1.0/avatars/aquatic/31.svg");
}
.avatar-aquatic-26 {
  background-image: url("/1.0/avatars/aquatic/26.svg");
}
.avatar-aquatic-52 {
  background-image: url("/1.0/avatars/aquatic/52.svg");
}
.avatar-aquatic-41 {
  background-image: url("/1.0/avatars/aquatic/41.svg");
}
.avatar-aquatic-22 {
  background-image: url("/1.0/avatars/aquatic/22.svg");
}
.avatar-aquatic-14 {
  background-image: url("/1.0/avatars/aquatic/14.svg");
}
.avatar-aquatic-55 {
  background-image: url("/1.0/avatars/aquatic/55.svg");
}
.avatar-aquatic-10 {
  background-image: url("/1.0/avatars/aquatic/10.svg");
}
.avatar-aquatic-51 {
  background-image: url("/1.0/avatars/aquatic/51.svg");
}
.avatar-aquatic-2 {
  background-image: url("/1.0/avatars/aquatic/2.svg");
}
.avatar-aquatic-23 {
  background-image: url("/1.0/avatars/aquatic/23.svg");
}
.avatar-aquatic-64 {
  background-image: url("/1.0/avatars/aquatic/64.svg");
}
.avatar-aquatic-47 {
  background-image: url("/1.0/avatars/aquatic/47.svg");
}
.avatar-aquatic-11 {
  background-image: url("/1.0/avatars/aquatic/11.svg");
}
.avatar-aquatic-25 {
  background-image: url("/1.0/avatars/aquatic/25.svg");
}
.avatar-aquatic-39 {
  background-image: url("/1.0/avatars/aquatic/39.svg");
}
.avatar-aquatic-58 {
  background-image: url("/1.0/avatars/aquatic/58.svg");
}
.avatar-aquatic-45 {
  background-image: url("/1.0/avatars/aquatic/45.svg");
}
.avatar-aquatic-42 {
  background-image: url("/1.0/avatars/aquatic/42.svg");
}
.avatar-aquatic-36 {
  background-image: url("/1.0/avatars/aquatic/36.svg");
}
.avatar-aquatic-61 {
  background-image: url("/1.0/avatars/aquatic/61.svg");
}
.avatar-aquatic-13 {
  background-image: url("/1.0/avatars/aquatic/13.svg");
}
.avatar-aquatic-32 {
  background-image: url("/1.0/avatars/aquatic/32.svg");
}
.avatar-aquatic-44 {
  background-image: url("/1.0/avatars/aquatic/44.svg");
}
.avatar-aquatic-37 {
  background-image: url("/1.0/avatars/aquatic/37.svg");
}
.avatar-aquatic-8 {
  background-image: url("/1.0/avatars/aquatic/8.svg");
}
.avatar-aquatic-7 {
  background-image: url("/1.0/avatars/aquatic/7.svg");
}
.avatar-aquatic-48 {
  background-image: url("/1.0/avatars/aquatic/48.svg");
}
.avatar-aquatic-59 {
  background-image: url("/1.0/avatars/aquatic/59.svg");
}
.avatar-aquatic-49 {
  background-image: url("/1.0/avatars/aquatic/49.svg");
}
.avatar-aquatic-34 {
  background-image: url("/1.0/avatars/aquatic/34.svg");
}
.avatar-aquatic-28 {
  background-image: url("/1.0/avatars/aquatic/28.svg");
}
.avatar-bird-5 {
  background-image: url("/1.0/avatars/bird/5.svg");
}
.avatar-bird-35 {
  background-image: url("/1.0/avatars/bird/35.svg");
}
.avatar-bird-2 {
  background-image: url("/1.0/avatars/bird/2.svg");
}
.avatar-bird-8 {
  background-image: url("/1.0/avatars/bird/8.svg");
}
.avatar-bird-23 {
  background-image: url("/1.0/avatars/bird/23.svg");
}
.avatar-bird-18 {
  background-image: url("/1.0/avatars/bird/18.svg");
}
.avatar-bird-6 {
  background-image: url("/1.0/avatars/bird/6.svg");
}
.avatar-bird-34 {
  background-image: url("/1.0/avatars/bird/34.svg");
}
.avatar-bird-7 {
  background-image: url("/1.0/avatars/bird/7.svg");
}
.avatar-bird-19 {
  background-image: url("/1.0/avatars/bird/19.svg");
}
.avatar-bird-14 {
  background-image: url("/1.0/avatars/bird/14.svg");
}
.avatar-bird-30 {
  background-image: url("/1.0/avatars/bird/30.svg");
}
.avatar-bird-29 {
  background-image: url("/1.0/avatars/bird/29.svg");
}
.avatar-bird-20 {
  background-image: url("/1.0/avatars/bird/20.svg");
}
.avatar-bird-12 {
  background-image: url("/1.0/avatars/bird/12.svg");
}
.avatar-bird-26 {
  background-image: url("/1.0/avatars/bird/26.svg");
}
.avatar-bird-31 {
  background-image: url("/1.0/avatars/bird/31.svg");
}
.avatar-bird-9 {
  background-image: url("/1.0/avatars/bird/9.svg");
}
.avatar-bird-16 {
  background-image: url("/1.0/avatars/bird/16.svg");
}
.avatar-bird-4 {
  background-image: url("/1.0/avatars/bird/4.svg");
}
.avatar-bird-28 {
  background-image: url("/1.0/avatars/bird/28.svg");
}
.avatar-bird-21 {
  background-image: url("/1.0/avatars/bird/21.svg");
}
.avatar-bird-13 {
  background-image: url("/1.0/avatars/bird/13.svg");
}
.avatar-bird-27 {
  background-image: url("/1.0/avatars/bird/27.svg");
}
.avatar-bird-22 {
  background-image: url("/1.0/avatars/bird/22.svg");
}
.avatar-bird-10 {
  background-image: url("/1.0/avatars/bird/10.svg");
}
.avatar-bird-15 {
  background-image: url("/1.0/avatars/bird/15.svg");
}
.avatar-bird-36 {
  background-image: url("/1.0/avatars/bird/36.svg");
}
.avatar-bird-17 {
  background-image: url("/1.0/avatars/bird/17.svg");
}
.avatar-bird-32 {
  background-image: url("/1.0/avatars/bird/32.svg");
}
.avatar-bird-11 {
  background-image: url("/1.0/avatars/bird/11.svg");
}
.avatar-bird-25 {
  background-image: url("/1.0/avatars/bird/25.svg");
}
.avatar-bird-33 {
  background-image: url("/1.0/avatars/bird/33.svg");
}
.avatar-bird-24 {
  background-image: url("/1.0/avatars/bird/24.svg");
}
.avatar-bird-38 {
  background-image: url("/1.0/avatars/bird/38.svg");
}
.avatar-bird-3 {
  background-image: url("/1.0/avatars/bird/3.svg");
}
.avatar-bird-37 {
  background-image: url("/1.0/avatars/bird/37.svg");
}
.avatar-fuzzball-177 {
  background-image: url("/1.0/avatars/fuzzball/177.svg");
}
.avatar-fuzzball-133 {
  background-image: url("/1.0/avatars/fuzzball/133.svg");
}
.avatar-fuzzball-64 {
  background-image: url("/1.0/avatars/fuzzball/64.svg");
}
.avatar-fuzzball-144 {
  background-image: url("/1.0/avatars/fuzzball/144.svg");
}
.avatar-fuzzball-176 {
  background-image: url("/1.0/avatars/fuzzball/176.svg");
}
.avatar-fuzzball-121 {
  background-image: url("/1.0/avatars/fuzzball/121.svg");
}
.avatar-fuzzball-65 {
  background-image: url("/1.0/avatars/fuzzball/65.svg");
}
.avatar-fuzzball-62 {
  background-image: url("/1.0/avatars/fuzzball/62.svg");
}
.avatar-fuzzball-159 {
  background-image: url("/1.0/avatars/fuzzball/159.svg");
}
.avatar-fuzzball-129 {
  background-image: url("/1.0/avatars/fuzzball/129.svg");
}
.avatar-fuzzball-57 {
  background-image: url("/1.0/avatars/fuzzball/57.svg");
}
.avatar-fuzzball-150 {
  background-image: url("/1.0/avatars/fuzzball/150.svg");
}
.avatar-fuzzball-68 {
  background-image: url("/1.0/avatars/fuzzball/68.svg");
}
.avatar-fuzzball-115 {
  background-image: url("/1.0/avatars/fuzzball/115.svg");
}
.avatar-fuzzball-12 {
  background-image: url("/1.0/avatars/fuzzball/12.svg");
}
.avatar-fuzzball-146 {
  background-image: url("/1.0/avatars/fuzzball/146.svg");
}
.avatar-fuzzball-5 {
  background-image: url("/1.0/avatars/fuzzball/5.svg");
}
.avatar-fuzzball-9 {
  background-image: url("/1.0/avatars/fuzzball/9.svg");
}
.avatar-fuzzball-39 {
  background-image: url("/1.0/avatars/fuzzball/39.svg");
}
.avatar-fuzzball-145 {
  background-image: url("/1.0/avatars/fuzzball/145.svg");
}
.avatar-fuzzball-124 {
  background-image: url("/1.0/avatars/fuzzball/124.svg");
}
.avatar-fuzzball-122 {
  background-image: url("/1.0/avatars/fuzzball/122.svg");
}
.avatar-fuzzball-63 {
  background-image: url("/1.0/avatars/fuzzball/63.svg");
}
.avatar-fuzzball-11 {
  background-image: url("/1.0/avatars/fuzzball/11.svg");
}
.avatar-fuzzball-169 {
  background-image: url("/1.0/avatars/fuzzball/169.svg");
}
.avatar-fuzzball-45 {
  background-image: url("/1.0/avatars/fuzzball/45.svg");
}
.avatar-fuzzball-56 {
  background-image: url("/1.0/avatars/fuzzball/56.svg");
}
.avatar-fuzzball-178 {
  background-image: url("/1.0/avatars/fuzzball/178.svg");
}
.avatar-fuzzball-123 {
  background-image: url("/1.0/avatars/fuzzball/123.svg");
}
.avatar-fuzzball-148 {
  background-image: url("/1.0/avatars/fuzzball/148.svg");
}
.avatar-fuzzball-26 {
  background-image: url("/1.0/avatars/fuzzball/26.svg");
}
.avatar-fuzzball-28 {
  background-image: url("/1.0/avatars/fuzzball/28.svg");
}
.avatar-fuzzball-97 {
  background-image: url("/1.0/avatars/fuzzball/97.svg");
}
.avatar-fuzzball-88 {
  background-image: url("/1.0/avatars/fuzzball/88.svg");
}
.avatar-fuzzball-60 {
  background-image: url("/1.0/avatars/fuzzball/60.svg");
}
.avatar-fuzzball-6 {
  background-image: url("/1.0/avatars/fuzzball/6.svg");
}
.avatar-fuzzball-74 {
  background-image: url("/1.0/avatars/fuzzball/74.svg");
}
.avatar-fuzzball-23 {
  background-image: url("/1.0/avatars/fuzzball/23.svg");
}
.avatar-fuzzball-171 {
  background-image: url("/1.0/avatars/fuzzball/171.svg");
}
.avatar-fuzzball-21 {
  background-image: url("/1.0/avatars/fuzzball/21.svg");
}
.avatar-fuzzball-81 {
  background-image: url("/1.0/avatars/fuzzball/81.svg");
}
.avatar-fuzzball-154 {
  background-image: url("/1.0/avatars/fuzzball/154.svg");
}
.avatar-fuzzball-94 {
  background-image: url("/1.0/avatars/fuzzball/94.svg");
}
.avatar-fuzzball-135 {
  background-image: url("/1.0/avatars/fuzzball/135.svg");
}
.avatar-fuzzball-51 {
  background-image: url("/1.0/avatars/fuzzball/51.svg");
}
.avatar-fuzzball-31 {
  background-image: url("/1.0/avatars/fuzzball/31.svg");
}
.avatar-fuzzball-138 {
  background-image: url("/1.0/avatars/fuzzball/138.svg");
}
.avatar-fuzzball-158 {
  background-image: url("/1.0/avatars/fuzzball/158.svg");
}
.avatar-fuzzball-84 {
  background-image: url("/1.0/avatars/fuzzball/84.svg");
}
.avatar-fuzzball-15 {
  background-image: url("/1.0/avatars/fuzzball/15.svg");
}
.avatar-fuzzball-119 {
  background-image: url("/1.0/avatars/fuzzball/119.svg");
}
.avatar-fuzzball-46 {
  background-image: url("/1.0/avatars/fuzzball/46.svg");
}
.avatar-fuzzball-38 {
  background-image: url("/1.0/avatars/fuzzball/38.svg");
}
.avatar-fuzzball-156 {
  background-image: url("/1.0/avatars/fuzzball/156.svg");
}
.avatar-fuzzball-157 {
  background-image: url("/1.0/avatars/fuzzball/157.svg");
}
.avatar-fuzzball-40 {
  background-image: url("/1.0/avatars/fuzzball/40.svg");
}
.avatar-fuzzball-153 {
  background-image: url("/1.0/avatars/fuzzball/153.svg");
}
.avatar-fuzzball-1 {
  background-image: url("/1.0/avatars/fuzzball/1.svg");
}
.avatar-fuzzball-92 {
  background-image: url("/1.0/avatars/fuzzball/92.svg");
}
.avatar-fuzzball-108 {
  background-image: url("/1.0/avatars/fuzzball/108.svg");
}
.avatar-fuzzball-55 {
  background-image: url("/1.0/avatars/fuzzball/55.svg");
}
.avatar-fuzzball-172 {
  background-image: url("/1.0/avatars/fuzzball/172.svg");
}
.avatar-fuzzball-162 {
  background-image: url("/1.0/avatars/fuzzball/162.svg");
}
.avatar-fuzzball-44 {
  background-image: url("/1.0/avatars/fuzzball/44.svg");
}
.avatar-fuzzball-112 {
  background-image: url("/1.0/avatars/fuzzball/112.svg");
}
.avatar-fuzzball-99 {
  background-image: url("/1.0/avatars/fuzzball/99.svg");
}
.avatar-fuzzball-109 {
  background-image: url("/1.0/avatars/fuzzball/109.svg");
}
.avatar-fuzzball-47 {
  background-image: url("/1.0/avatars/fuzzball/47.svg");
}
.avatar-fuzzball-149 {
  background-image: url("/1.0/avatars/fuzzball/149.svg");
}
.avatar-fuzzball-86 {
  background-image: url("/1.0/avatars/fuzzball/86.svg");
}
.avatar-fuzzball-139 {
  background-image: url("/1.0/avatars/fuzzball/139.svg");
}
.avatar-fuzzball-36 {
  background-image: url("/1.0/avatars/fuzzball/36.svg");
}
.avatar-fuzzball-30 {
  background-image: url("/1.0/avatars/fuzzball/30.svg");
}
.avatar-fuzzball-118 {
  background-image: url("/1.0/avatars/fuzzball/118.svg");
}
.avatar-fuzzball-25 {
  background-image: url("/1.0/avatars/fuzzball/25.svg");
}
.avatar-fuzzball-161 {
  background-image: url("/1.0/avatars/fuzzball/161.svg");
}
.avatar-fuzzball-167 {
  background-image: url("/1.0/avatars/fuzzball/167.svg");
}
.avatar-fuzzball-52 {
  background-image: url("/1.0/avatars/fuzzball/52.svg");
}
.avatar-fuzzball-93 {
  background-image: url("/1.0/avatars/fuzzball/93.svg");
}
.avatar-fuzzball-132 {
  background-image: url("/1.0/avatars/fuzzball/132.svg");
}
.avatar-fuzzball-77 {
  background-image: url("/1.0/avatars/fuzzball/77.svg");
}
.avatar-fuzzball-32 {
  background-image: url("/1.0/avatars/fuzzball/32.svg");
}
.avatar-fuzzball-22 {
  background-image: url("/1.0/avatars/fuzzball/22.svg");
}
.avatar-fuzzball-113 {
  background-image: url("/1.0/avatars/fuzzball/113.svg");
}
.avatar-fuzzball-17 {
  background-image: url("/1.0/avatars/fuzzball/17.svg");
}
.avatar-fuzzball-103 {
  background-image: url("/1.0/avatars/fuzzball/103.svg");
}
.avatar-fuzzball-170 {
  background-image: url("/1.0/avatars/fuzzball/170.svg");
}
.avatar-fuzzball-7 {
  background-image: url("/1.0/avatars/fuzzball/7.svg");
}
.avatar-fuzzball-20 {
  background-image: url("/1.0/avatars/fuzzball/20.svg");
}
.avatar-fuzzball-70 {
  background-image: url("/1.0/avatars/fuzzball/70.svg");
}
.avatar-fuzzball-33 {
  background-image: url("/1.0/avatars/fuzzball/33.svg");
}
.avatar-fuzzball-141 {
  background-image: url("/1.0/avatars/fuzzball/141.svg");
}
.avatar-fuzzball-27 {
  background-image: url("/1.0/avatars/fuzzball/27.svg");
}
.avatar-fuzzball-89 {
  background-image: url("/1.0/avatars/fuzzball/89.svg");
}
.avatar-fuzzball-41 {
  background-image: url("/1.0/avatars/fuzzball/41.svg");
}
.avatar-fuzzball-24 {
  background-image: url("/1.0/avatars/fuzzball/24.svg");
}
.avatar-fuzzball-152 {
  background-image: url("/1.0/avatars/fuzzball/152.svg");
}
.avatar-fuzzball-19 {
  background-image: url("/1.0/avatars/fuzzball/19.svg");
}
.avatar-fuzzball-4 {
  background-image: url("/1.0/avatars/fuzzball/4.svg");
}
.avatar-fuzzball-85 {
  background-image: url("/1.0/avatars/fuzzball/85.svg");
}
.avatar-fuzzball-110 {
  background-image: url("/1.0/avatars/fuzzball/110.svg");
}
.avatar-fuzzball-37 {
  background-image: url("/1.0/avatars/fuzzball/37.svg");
}
.avatar-fuzzball-173 {
  background-image: url("/1.0/avatars/fuzzball/173.svg");
}
.avatar-fuzzball-80 {
  background-image: url("/1.0/avatars/fuzzball/80.svg");
}
.avatar-fuzzball-29 {
  background-image: url("/1.0/avatars/fuzzball/29.svg");
}
.avatar-fuzzball-131 {
  background-image: url("/1.0/avatars/fuzzball/131.svg");
}
.avatar-fuzzball-90 {
  background-image: url("/1.0/avatars/fuzzball/90.svg");
}
.avatar-fuzzball-142 {
  background-image: url("/1.0/avatars/fuzzball/142.svg");
}
.avatar-fuzzball-78 {
  background-image: url("/1.0/avatars/fuzzball/78.svg");
}
.avatar-fuzzball-8 {
  background-image: url("/1.0/avatars/fuzzball/8.svg");
}
.avatar-fuzzball-130 {
  background-image: url("/1.0/avatars/fuzzball/130.svg");
}
.avatar-fuzzball-160 {
  background-image: url("/1.0/avatars/fuzzball/160.svg");
}
.avatar-fuzzball-111 {
  background-image: url("/1.0/avatars/fuzzball/111.svg");
}
.avatar-fuzzball-143 {
  background-image: url("/1.0/avatars/fuzzball/143.svg");
}
.avatar-fuzzball-49 {
  background-image: url("/1.0/avatars/fuzzball/49.svg");
}
.avatar-fuzzball-91 {
  background-image: url("/1.0/avatars/fuzzball/91.svg");
}
.avatar-fuzzball-16 {
  background-image: url("/1.0/avatars/fuzzball/16.svg");
}
.avatar-fuzzball-137 {
  background-image: url("/1.0/avatars/fuzzball/137.svg");
}
.avatar-fuzzball-127 {
  background-image: url("/1.0/avatars/fuzzball/127.svg");
}
.avatar-fuzzball-87 {
  background-image: url("/1.0/avatars/fuzzball/87.svg");
}
.avatar-fuzzball-163 {
  background-image: url("/1.0/avatars/fuzzball/163.svg");
}
.avatar-fuzzball-3 {
  background-image: url("/1.0/avatars/fuzzball/3.svg");
}
.avatar-fuzzball-34 {
  background-image: url("/1.0/avatars/fuzzball/34.svg");
}
.avatar-fuzzball-71 {
  background-image: url("/1.0/avatars/fuzzball/71.svg");
}
.avatar-fuzzball-61 {
  background-image: url("/1.0/avatars/fuzzball/61.svg");
}
.avatar-fuzzball-69 {
  background-image: url("/1.0/avatars/fuzzball/69.svg");
}
.avatar-fuzzball-54 {
  background-image: url("/1.0/avatars/fuzzball/54.svg");
}
.avatar-fuzzball-59 {
  background-image: url("/1.0/avatars/fuzzball/59.svg");
}
.avatar-fuzzball-14 {
  background-image: url("/1.0/avatars/fuzzball/14.svg");
}
.avatar-fuzzball-35 {
  background-image: url("/1.0/avatars/fuzzball/35.svg");
}
.avatar-fuzzball-43 {
  background-image: url("/1.0/avatars/fuzzball/43.svg");
}
.avatar-fuzzball-58 {
  background-image: url("/1.0/avatars/fuzzball/58.svg");
}
.avatar-fuzzball-96 {
  background-image: url("/1.0/avatars/fuzzball/96.svg");
}
.avatar-fuzzball-147 {
  background-image: url("/1.0/avatars/fuzzball/147.svg");
}
.avatar-fuzzball-95 {
  background-image: url("/1.0/avatars/fuzzball/95.svg");
}
.avatar-fuzzball-116 {
  background-image: url("/1.0/avatars/fuzzball/116.svg");
}
.avatar-fuzzball-79 {
  background-image: url("/1.0/avatars/fuzzball/79.svg");
}
.avatar-fuzzball-75 {
  background-image: url("/1.0/avatars/fuzzball/75.svg");
}
.avatar-fuzzball-107 {
  background-image: url("/1.0/avatars/fuzzball/107.svg");
}
.avatar-fuzzball-179 {
  background-image: url("/1.0/avatars/fuzzball/179.svg");
}
.avatar-fuzzball-82 {
  background-image: url("/1.0/avatars/fuzzball/82.svg");
}
.avatar-fuzzball-165 {
  background-image: url("/1.0/avatars/fuzzball/165.svg");
}
.avatar-fuzzball-140 {
  background-image: url("/1.0/avatars/fuzzball/140.svg");
}
.avatar-fuzzball-164 {
  background-image: url("/1.0/avatars/fuzzball/164.svg");
}
.avatar-fuzzball-101 {
  background-image: url("/1.0/avatars/fuzzball/101.svg");
}
.avatar-fuzzball-83 {
  background-image: url("/1.0/avatars/fuzzball/83.svg");
}
.avatar-fuzzball-166 {
  background-image: url("/1.0/avatars/fuzzball/166.svg");
}
.avatar-fuzzball-104 {
  background-image: url("/1.0/avatars/fuzzball/104.svg");
}
.avatar-fuzzball-175 {
  background-image: url("/1.0/avatars/fuzzball/175.svg");
}
.avatar-fuzzball-136 {
  background-image: url("/1.0/avatars/fuzzball/136.svg");
}
.avatar-fuzzball-120 {
  background-image: url("/1.0/avatars/fuzzball/120.svg");
}
.avatar-fuzzball-100 {
  background-image: url("/1.0/avatars/fuzzball/100.svg");
}
.avatar-fuzzball-66 {
  background-image: url("/1.0/avatars/fuzzball/66.svg");
}
.avatar-fuzzball-18 {
  background-image: url("/1.0/avatars/fuzzball/18.svg");
}
.avatar-fuzzball-53 {
  background-image: url("/1.0/avatars/fuzzball/53.svg");
}
.avatar-fuzzball-72 {
  background-image: url("/1.0/avatars/fuzzball/72.svg");
}
.avatar-fuzzball-102 {
  background-image: url("/1.0/avatars/fuzzball/102.svg");
}
.avatar-fuzzball-125 {
  background-image: url("/1.0/avatars/fuzzball/125.svg");
}
.avatar-fuzzball-67 {
  background-image: url("/1.0/avatars/fuzzball/67.svg");
}
.avatar-fuzzball-98 {
  background-image: url("/1.0/avatars/fuzzball/98.svg");
}
.avatar-fuzzball-73 {
  background-image: url("/1.0/avatars/fuzzball/73.svg");
}
.avatar-fuzzball-10 {
  background-image: url("/1.0/avatars/fuzzball/10.svg");
}
.avatar-fuzzball-128 {
  background-image: url("/1.0/avatars/fuzzball/128.svg");
}
.avatar-fuzzball-134 {
  background-image: url("/1.0/avatars/fuzzball/134.svg");
}
.avatar-fuzzball-126 {
  background-image: url("/1.0/avatars/fuzzball/126.svg");
}
.avatar-fuzzball-168 {
  background-image: url("/1.0/avatars/fuzzball/168.svg");
}
.avatar-fuzzball-76 {
  background-image: url("/1.0/avatars/fuzzball/76.svg");
}
.avatar-fuzzball-117 {
  background-image: url("/1.0/avatars/fuzzball/117.svg");
}
.avatar-fuzzball-13 {
  background-image: url("/1.0/avatars/fuzzball/13.svg");
}
.avatar-fuzzball-48 {
  background-image: url("/1.0/avatars/fuzzball/48.svg");
}
.avatar-fuzzball-155 {
  background-image: url("/1.0/avatars/fuzzball/155.svg");
}
.avatar-fuzzball-105 {
  background-image: url("/1.0/avatars/fuzzball/105.svg");
}
.avatar-fuzzball-106 {
  background-image: url("/1.0/avatars/fuzzball/106.svg");
}
.avatar-fuzzball-114 {
  background-image: url("/1.0/avatars/fuzzball/114.svg");
}
.avatar-fuzzball-174 {
  background-image: url("/1.0/avatars/fuzzball/174.svg");
}
.avatar-fuzzball-2 {
  background-image: url("/1.0/avatars/fuzzball/2.svg");
}
.avatar-fuzzball-42 {
  background-image: url("/1.0/avatars/fuzzball/42.svg");
}
.avatar-fuzzball-151 {
  background-image: url("/1.0/avatars/fuzzball/151.svg");
}
.avatar-wisdom-27 {
  background-image: url("/1.0/avatars/wisdom/27.svg");
}
.avatar-wisdom-36 {
  background-image: url("/1.0/avatars/wisdom/36.svg");
}
.avatar-wisdom-8 {
  background-image: url("/1.0/avatars/wisdom/8.svg");
}
.avatar-wisdom-67 {
  background-image: url("/1.0/avatars/wisdom/67.svg");
}
.avatar-wisdom-54 {
  background-image: url("/1.0/avatars/wisdom/54.svg");
}
.avatar-wisdom-86 {
  background-image: url("/1.0/avatars/wisdom/86.svg");
}
.avatar-wisdom-30 {
  background-image: url("/1.0/avatars/wisdom/30.svg");
}
.avatar-wisdom-51 {
  background-image: url("/1.0/avatars/wisdom/51.svg");
}
.avatar-wisdom-35 {
  background-image: url("/1.0/avatars/wisdom/35.svg");
}
.avatar-wisdom-64 {
  background-image: url("/1.0/avatars/wisdom/64.svg");
}
.avatar-wisdom-28 {
  background-image: url("/1.0/avatars/wisdom/28.svg");
}
.avatar-wisdom-87 {
  background-image: url("/1.0/avatars/wisdom/87.svg");
}
.avatar-wisdom-53 {
  background-image: url("/1.0/avatars/wisdom/53.svg");
}
.avatar-wisdom-46 {
  background-image: url("/1.0/avatars/wisdom/46.svg");
}
.avatar-wisdom-78 {
  background-image: url("/1.0/avatars/wisdom/78.svg");
}
.avatar-wisdom-56 {
  background-image: url("/1.0/avatars/wisdom/56.svg");
}
.avatar-wisdom-19 {
  background-image: url("/1.0/avatars/wisdom/19.svg");
}
.avatar-wisdom-73 {
  background-image: url("/1.0/avatars/wisdom/73.svg");
}
.avatar-wisdom-97 {
  background-image: url("/1.0/avatars/wisdom/97.svg");
}
.avatar-wisdom-71 {
  background-image: url("/1.0/avatars/wisdom/71.svg");
}
.avatar-wisdom-93 {
  background-image: url("/1.0/avatars/wisdom/93.svg");
}
.avatar-wisdom-33 {
  background-image: url("/1.0/avatars/wisdom/33.svg");
}
.avatar-wisdom-80 {
  background-image: url("/1.0/avatars/wisdom/80.svg");
}
.avatar-wisdom-34 {
  background-image: url("/1.0/avatars/wisdom/34.svg");
}
.avatar-wisdom-66 {
  background-image: url("/1.0/avatars/wisdom/66.svg");
}
.avatar-wisdom-7 {
  background-image: url("/1.0/avatars/wisdom/7.svg");
}
.avatar-wisdom-11 {
  background-image: url("/1.0/avatars/wisdom/11.svg");
}
.avatar-wisdom-52 {
  background-image: url("/1.0/avatars/wisdom/52.svg");
}
.avatar-wisdom-60 {
  background-image: url("/1.0/avatars/wisdom/60.svg");
}
.avatar-wisdom-17 {
  background-image: url("/1.0/avatars/wisdom/17.svg");
}
.avatar-wisdom-81 {
  background-image: url("/1.0/avatars/wisdom/81.svg");
}
.avatar-wisdom-37 {
  background-image: url("/1.0/avatars/wisdom/37.svg");
}
.avatar-wisdom-49 {
  background-image: url("/1.0/avatars/wisdom/49.svg");
}
.avatar-wisdom-40 {
  background-image: url("/1.0/avatars/wisdom/40.svg");
}
.avatar-wisdom-25 {
  background-image: url("/1.0/avatars/wisdom/25.svg");
}
.avatar-wisdom-39 {
  background-image: url("/1.0/avatars/wisdom/39.svg");
}
.avatar-wisdom-61 {
  background-image: url("/1.0/avatars/wisdom/61.svg");
}
.avatar-wisdom-18 {
  background-image: url("/1.0/avatars/wisdom/18.svg");
}
.avatar-wisdom-58 {
  background-image: url("/1.0/avatars/wisdom/58.svg");
}
.avatar-wisdom-32 {
  background-image: url("/1.0/avatars/wisdom/32.svg");
}
.avatar-wisdom-69 {
  background-image: url("/1.0/avatars/wisdom/69.svg");
}
.avatar-wisdom-70 {
  background-image: url("/1.0/avatars/wisdom/70.svg");
}
.avatar-wisdom-22 {
  background-image: url("/1.0/avatars/wisdom/22.svg");
}
.avatar-wisdom-91 {
  background-image: url("/1.0/avatars/wisdom/91.svg");
}
.avatar-wisdom-23 {
  background-image: url("/1.0/avatars/wisdom/23.svg");
}
.avatar-wisdom-85 {
  background-image: url("/1.0/avatars/wisdom/85.svg");
}
.avatar-wisdom-65 {
  background-image: url("/1.0/avatars/wisdom/65.svg");
}
.avatar-wisdom-77 {
  background-image: url("/1.0/avatars/wisdom/77.svg");
}
.avatar-wisdom-84 {
  background-image: url("/1.0/avatars/wisdom/84.svg");
}
.avatar-wisdom-43 {
  background-image: url("/1.0/avatars/wisdom/43.svg");
}
.avatar-wisdom-59 {
  background-image: url("/1.0/avatars/wisdom/59.svg");
}
.avatar-wisdom-90 {
  background-image: url("/1.0/avatars/wisdom/90.svg");
}
.avatar-wisdom-89 {
  background-image: url("/1.0/avatars/wisdom/89.svg");
}
.avatar-wisdom-4 {
  background-image: url("/1.0/avatars/wisdom/4.svg");
}
.avatar-wisdom-48 {
  background-image: url("/1.0/avatars/wisdom/48.svg");
}
.avatar-wisdom-20 {
  background-image: url("/1.0/avatars/wisdom/20.svg");
}
.avatar-wisdom-6 {
  background-image: url("/1.0/avatars/wisdom/6.svg");
}
.avatar-wisdom-24 {
  background-image: url("/1.0/avatars/wisdom/24.svg");
}
.avatar-wisdom-21 {
  background-image: url("/1.0/avatars/wisdom/21.svg");
}
.avatar-wisdom-2 {
  background-image: url("/1.0/avatars/wisdom/2.svg");
}
.avatar-wisdom-45 {
  background-image: url("/1.0/avatars/wisdom/45.svg");
}
.avatar-wisdom-55 {
  background-image: url("/1.0/avatars/wisdom/55.svg");
}
.avatar-wisdom-92 {
  background-image: url("/1.0/avatars/wisdom/92.svg");
}
.avatar-wisdom-15 {
  background-image: url("/1.0/avatars/wisdom/15.svg");
}
.avatar-wisdom-47 {
  background-image: url("/1.0/avatars/wisdom/47.svg");
}
.avatar-wisdom-9 {
  background-image: url("/1.0/avatars/wisdom/9.svg");
}
.avatar-wisdom-10 {
  background-image: url("/1.0/avatars/wisdom/10.svg");
}
.avatar-wisdom-79 {
  background-image: url("/1.0/avatars/wisdom/79.svg");
}
.avatar-wisdom-44 {
  background-image: url("/1.0/avatars/wisdom/44.svg");
}
.avatar-wisdom-72 {
  background-image: url("/1.0/avatars/wisdom/72.svg");
}
.avatar-wisdom-31 {
  background-image: url("/1.0/avatars/wisdom/31.svg");
}
.avatar-wisdom-16 {
  background-image: url("/1.0/avatars/wisdom/16.svg");
}
.avatar-wisdom-98 {
  background-image: url("/1.0/avatars/wisdom/98.svg");
}
.avatar-wisdom-95 {
  background-image: url("/1.0/avatars/wisdom/95.svg");
}
.avatar-wisdom-5 {
  background-image: url("/1.0/avatars/wisdom/5.svg");
}
.avatar-wisdom-63 {
  background-image: url("/1.0/avatars/wisdom/63.svg");
}
.avatar-wisdom-94 {
  background-image: url("/1.0/avatars/wisdom/94.svg");
}
.avatar-wisdom-62 {
  background-image: url("/1.0/avatars/wisdom/62.svg");
}
.avatar-wisdom-29 {
  background-image: url("/1.0/avatars/wisdom/29.svg");
}
.avatar-wisdom-50 {
  background-image: url("/1.0/avatars/wisdom/50.svg");
}
.avatar-wisdom-42 {
  background-image: url("/1.0/avatars/wisdom/42.svg");
}
.avatar-wisdom-88 {
  background-image: url("/1.0/avatars/wisdom/88.svg");
}
.avatar-wisdom-75 {
  background-image: url("/1.0/avatars/wisdom/75.svg");
}
.avatar-wisdom-74 {
  background-image: url("/1.0/avatars/wisdom/74.svg");
}
.avatar-wisdom-82 {
  background-image: url("/1.0/avatars/wisdom/82.svg");
}
.avatar-wisdom-13 {
  background-image: url("/1.0/avatars/wisdom/13.svg");
}
.avatar-wisdom-57 {
  background-image: url("/1.0/avatars/wisdom/57.svg");
}
.avatar-wisdom-3 {
  background-image: url("/1.0/avatars/wisdom/3.svg");
}
.avatar-wisdom-26 {
  background-image: url("/1.0/avatars/wisdom/26.svg");
}
.avatar-wisdom-14 {
  background-image: url("/1.0/avatars/wisdom/14.svg");
}
.avatar-wisdom-76 {
  background-image: url("/1.0/avatars/wisdom/76.svg");
}
.avatar-wisdom-83 {
  background-image: url("/1.0/avatars/wisdom/83.svg");
}
.avatar-wisdom-41 {
  background-image: url("/1.0/avatars/wisdom/41.svg");
}
.avatar-wisdom-38 {
  background-image: url("/1.0/avatars/wisdom/38.svg");
}
.avatar-wisdom-68 {
  background-image: url("/1.0/avatars/wisdom/68.svg");
}
.avatar-wisdom-96 {
  background-image: url("/1.0/avatars/wisdom/96.svg");
}
.avatar-wisdom-12 {
  background-image: url("/1.0/avatars/wisdom/12.svg");
}
.avatar-robot-114 {
  background-image: url("/1.0/avatars/robot/114.svg");
}
.avatar-robot-115 {
  background-image: url("/1.0/avatars/robot/115.svg");
}
.avatar-robot-116 {
  background-image: url("/1.0/avatars/robot/116.svg");
}
.avatar-robot-117 {
  background-image: url("/1.0/avatars/robot/117.svg");
}
.avatar-robot-118 {
  background-image: url("/1.0/avatars/robot/118.svg");
}
.avatar-robot-119 {
  background-image: url("/1.0/avatars/robot/119.svg");
}
.avatar-robot-120 {
  background-image: url("/1.0/avatars/robot/120.svg");
}
.avatar-robot-121 {
  background-image: url("/1.0/avatars/robot/121.svg");
}
.avatar-robot-122 {
  background-image: url("/1.0/avatars/robot/122.svg");
}
.avatar-robot-123 {
  background-image: url("/1.0/avatars/robot/123.svg");
}
.avatar-robot-124 {
  background-image: url("/1.0/avatars/robot/124.svg");
}
.avatar-robot-125 {
  background-image: url("/1.0/avatars/robot/125.svg");
}
.avatar-robot-126 {
  background-image: url("/1.0/avatars/robot/126.svg");
}
.avatar-robot-127 {
  background-image: url("/1.0/avatars/robot/127.svg");
}
.avatar-robot-128 {
  background-image: url("/1.0/avatars/robot/128.svg");
}
.avatar-robot-129 {
  background-image: url("/1.0/avatars/robot/129.svg");
}
.avatar-robot-130 {
  background-image: url("/1.0/avatars/robot/130.svg");
}
.avatar-robot-131 {
  background-image: url("/1.0/avatars/robot/131.svg");
}
.avatar-robot-132 {
  background-image: url("/1.0/avatars/robot/132.svg");
}
.avatar-robot-133 {
  background-image: url("/1.0/avatars/robot/133.svg");
}
.avatar-robot-134 {
  background-image: url("/1.0/avatars/robot/134.svg");
}
.avatar-robot-135 {
  background-image: url("/1.0/avatars/robot/135.svg");
}
.avatar-robot-136 {
  background-image: url("/1.0/avatars/robot/136.svg");
}
.avatar-robot-137 {
  background-image: url("/1.0/avatars/robot/137.svg");
}
.avatar-robot-138 {
  background-image: url("/1.0/avatars/robot/138.svg");
}
.avatar-robot-139 {
  background-image: url("/1.0/avatars/robot/139.svg");
}
.avatar-robot-140 {
  background-image: url("/1.0/avatars/robot/140.svg");
}
.avatar-robot-141 {
  background-image: url("/1.0/avatars/robot/141.svg");
}
.avatar-robot-142 {
  background-image: url("/1.0/avatars/robot/142.svg");
}
.avatar-robot-143 {
  background-image: url("/1.0/avatars/robot/143.svg");
}
.avatar-robot-144 {
  background-image: url("/1.0/avatars/robot/144.svg");
}
.avatar-robot-145 {
  background-image: url("/1.0/avatars/robot/145.svg");
}
.avatar-robot-146 {
  background-image: url("/1.0/avatars/robot/146.svg");
}
.avatar-robot-147 {
  background-image: url("/1.0/avatars/robot/147.svg");
}
.avatar-robot-148 {
  background-image: url("/1.0/avatars/robot/148.svg");
}
.avatar-robot-149 {
  background-image: url("/1.0/avatars/robot/149.svg");
}
.avatar-robot-151 {
  background-image: url("/1.0/avatars/robot/151.svg");
}
.avatar-robot-152 {
  background-image: url("/1.0/avatars/robot/152.svg");
}
.avatar-robot-153 {
  background-image: url("/1.0/avatars/robot/153.svg");
}
.avatar-robot-154 {
  background-image: url("/1.0/avatars/robot/154.svg");
}
.avatar-robot-155 {
  background-image: url("/1.0/avatars/robot/155.svg");
}
.avatar-robot-156 {
  background-image: url("/1.0/avatars/robot/156.svg");
}
.avatar-robot-158 {
  background-image: url("/1.0/avatars/robot/158.svg");
}
.avatar-robot-159 {
  background-image: url("/1.0/avatars/robot/159.svg");
}
.avatar-robot-160 {
  background-image: url("/1.0/avatars/robot/160.svg");
}
.avatar-robot-161 {
  background-image: url("/1.0/avatars/robot/161.svg");
}
.avatar-robot-162 {
  background-image: url("/1.0/avatars/robot/162.svg");
}
.avatar-robot-163 {
  background-image: url("/1.0/avatars/robot/163.svg");
}
.avatar-robot-165 {
  background-image: url("/1.0/avatars/robot/165.svg");
}
.avatar-robot-166 {
  background-image: url("/1.0/avatars/robot/166.svg");
}
.avatar-robot-167 {
  background-image: url("/1.0/avatars/robot/167.svg");
}
.avatar-robot-168 {
  background-image: url("/1.0/avatars/robot/168.svg");
}
.avatar-robot-169 {
  background-image: url("/1.0/avatars/robot/169.svg");
}
.avatar-robot-170 {
  background-image: url("/1.0/avatars/robot/170.svg");
}
.avatar-robot-171 {
  background-image: url("/1.0/avatars/robot/171.svg");
}
.avatar-robot-172 {
  background-image: url("/1.0/avatars/robot/172.svg");
}
.avatar-robot-173 {
  background-image: url("/1.0/avatars/robot/173.svg");
}
.avatar-robot-174 {
  background-image: url("/1.0/avatars/robot/174.svg");
}
.avatar-robot-175 {
  background-image: url("/1.0/avatars/robot/175.svg");
}
.avatar-robot-176 {
  background-image: url("/1.0/avatars/robot/176.svg");
}
.avatar-robot-177 {
  background-image: url("/1.0/avatars/robot/177.svg");
}
.avatar-robot-178 {
  background-image: url("/1.0/avatars/robot/178.svg");
}
.avatar-robot-179 {
  background-image: url("/1.0/avatars/robot/179.svg");
}
.avatar-robot-180 {
  background-image: url("/1.0/avatars/robot/180.svg");
}
.avatar-robot-181 {
  background-image: url("/1.0/avatars/robot/181.svg");
}
.avatar-robot-182 {
  background-image: url("/1.0/avatars/robot/182.svg");
}
.avatar-robot-183 {
  background-image: url("/1.0/avatars/robot/183.svg");
}
.avatar-robot-184 {
  background-image: url("/1.0/avatars/robot/184.svg");
}
.avatar-robot-185 {
  background-image: url("/1.0/avatars/robot/185.svg");
}
.avatar-robot-186 {
  background-image: url("/1.0/avatars/robot/186.svg");
}
.avatar-robot-187 {
  background-image: url("/1.0/avatars/robot/187.svg");
}
.avatar-robot-188 {
  background-image: url("/1.0/avatars/robot/188.svg");
}
.avatar-robot-189 {
  background-image: url("/1.0/avatars/robot/189.svg");
}
.avatar-robot-190 {
  background-image: url("/1.0/avatars/robot/190.svg");
}
.avatar-robot-191 {
  background-image: url("/1.0/avatars/robot/191.svg");
}
.avatar-robot-192 {
  background-image: url("/1.0/avatars/robot/192.svg");
}
.avatar-robot-193 {
  background-image: url("/1.0/avatars/robot/193.svg");
}
.avatar-robot-194 {
  background-image: url("/1.0/avatars/robot/194.svg");
}
.avatar-robot-195 {
  background-image: url("/1.0/avatars/robot/195.svg");
}
.avatar-robot-196 {
  background-image: url("/1.0/avatars/robot/196.svg");
}
.avatar-robot-197 {
  background-image: url("/1.0/avatars/robot/197.svg");
}
.avatar-robot-198 {
  background-image: url("/1.0/avatars/robot/198.svg");
}
.avatar-robot-200 {
  background-image: url("/1.0/avatars/robot/200.svg");
}
.avatar-robot-201 {
  background-image: url("/1.0/avatars/robot/201.svg");
}
.avatar-robot-206 {
  background-image: url("/1.0/avatars/robot/206.svg");
}
.avatar-robot-207 {
  background-image: url("/1.0/avatars/robot/207.svg");
}
.avatar-robot-214 {
  background-image: url("/1.0/avatars/robot/214.svg");
}
.avatar-robot-215 {
  background-image: url("/1.0/avatars/robot/215.svg");
}
.avatar-robot-220 {
  background-image: url("/1.0/avatars/robot/220.svg");
}
.avatar-robot-221 {
  background-image: url("/1.0/avatars/robot/221.svg");
}
.avatar-robot-228 {
  background-image: url("/1.0/avatars/robot/228.svg");
}
.avatar-robot-234 {
  background-image: url("/1.0/avatars/robot/234.svg");
}
.avatar-robot-242 {
  background-image: url("/1.0/avatars/robot/242.svg");
}
.avatar-robot-248 {
  background-image: url("/1.0/avatars/robot/248.svg");
}
.avatar-robot-256 {
  background-image: url("/1.0/avatars/robot/256.svg");
}
.avatar-robot-257 {
  background-image: url("/1.0/avatars/robot/257.svg");
}
.avatar-robot-258 {
  background-image: url("/1.0/avatars/robot/258.svg");
}
.avatar-robot-259 {
  background-image: url("/1.0/avatars/robot/259.svg");
}
.avatar-robot-260 {
  background-image: url("/1.0/avatars/robot/260.svg");
}
.avatar-robot-261 {
  background-image: url("/1.0/avatars/robot/261.svg");
}
.avatar-robot-262 {
  background-image: url("/1.0/avatars/robot/262.svg");
}
.avatar-robot-263 {
  background-image: url("/1.0/avatars/robot/263.svg");
}
.avatar-robot-264 {
  background-image: url("/1.0/avatars/robot/264.svg");
}
.avatar-robot-265 {
  background-image: url("/1.0/avatars/robot/265.svg");
}
.avatar-robot-266 {
  background-image: url("/1.0/avatars/robot/266.svg");
}
.avatar-robot-267 {
  background-image: url("/1.0/avatars/robot/267.svg");
}
.avatar-robot-268 {
  background-image: url("/1.0/avatars/robot/268.svg");
}
.avatar-robot-269 {
  background-image: url("/1.0/avatars/robot/269.svg");
}
.avatar-robot-270 {
  background-image: url("/1.0/avatars/robot/270.svg");
}
.avatar-robot-271 {
  background-image: url("/1.0/avatars/robot/271.svg");
}
.avatar-robot-272 {
  background-image: url("/1.0/avatars/robot/272.svg");
}
.avatar-robot-274 {
  background-image: url("/1.0/avatars/robot/274.svg");
}
.Avatar {
  flex: 1;
  display: inline-flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
}
.Avatar .Avatar-svg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  flex: 1;
  min-height: 5vh;
  min-width: 5vw;
  max-height: 15rem;
  max-width: 15rem;
}
.AvatarSelection {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 1rem;
}
.AvatarSelection .race-selection-icons button {
  border: 1px solid #888;
  border-radius: 0.25rem;
  margin: 0.2rem;
}
.AvatarSelection .race-selection-icons button.active {
  background-color: #75d4cb;
}
.AvatarSelection .selector {
  display: inline-flex;
  justify-content: center;
  margin-top: 1rem;
}
.AvatarSelection .selector .previous,
.AvatarSelection .selector .next {
  flex: 0;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
}
.AvatarSelection .race-selection-icons {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 1rem;
  padding-left: 2rem;
  background-color: rgba(255,255,255,0.2);
  border-radius: 1rem;
}
.AvatarSelection .Avatar-svg.icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border: 2px solid transparent;
  border-radius: 0.5rem;
}
.AvatarSelection .Avatar-svg.icon:hover,
.AvatarSelection .Avatar-svg.icon.active {
  cursor: pointer;
  border: 2px solid #fff;
  background-color: rgba(255,255,255,0.4);
}
.AvatarSelection .selector {
  flex: 1;
  display: flex;
  width: 80vw;
  align-items: stretch;
  align-content: center;
  justify-content: center;
}
.AvatarSelection .selector .previous,
.AvatarSelection .selector .next {
  flex: 0;
}
.AvatarSelection .selector .Avatar {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1;
  max-width: 50vw;
}
.AvatarSelection .selector .Avatar .Avatar-svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.AvatarSelection .race-selection-container {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.AvatarSelection .race-selection-container .race-selection-title {
  font-size: 0.9rem;
  letter-spacing: 0.2rem;
  background-color: rgba(40,40,40,0.8);
  border: 2px solid #fff;
  border-radius: 0.5rem;
  padding: 0.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #fff;
}
.pluses {
  color: #fff;
  font-family: fixed;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.BackButton {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: fixed;
  left: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 900;
}
.Bowl {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Bowl .spacer {
  flex: 1;
  flex-basis: 1rem;
}
.Bowl .Bowl-svg-background {
  position: relative;
  background-image: svg-load("../assets/misc/bowl-back.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 50%;
  min-height: 3rem;
  min-width: 3rem;
  max-height: 10rem;
  max-width: 10rem;
  aspect-ratio: 1/1;
}
.Bowl .Bowl-svg-background .Bowl-stone {
  position: absolute;
  top: 0;
  left: 0;
  height: 14%;
  aspect-ratio: 1/1;
}
.Bowl .Bowl-svg-background .Bowl-stone.s0 {
  left: 14%;
  top: -3%;
}
.Bowl .Bowl-svg-background .Bowl-stone.s1 {
  left: 28%;
  top: -2%;
}
.Bowl .Bowl-svg-background .Bowl-stone.s2 {
  left: 38%;
  top: -4%;
}
.Bowl .Bowl-svg-background .Bowl-stone.s3 {
  left: 53%;
  top: -2%;
}
.Bowl .Bowl-svg-background .Bowl-stone.s4 {
  left: 70%;
  top: -3%;
}
.Bowl .Bowl-svg-background .Bowl-svg-foreground {
  position: absolute;
  background-image: svg-load("../assets/misc/bowl-front.svg");
  background-repeat: no-repeat;
  z-index: 999;
  width: 100%;
  height: 100%;
  bottom: -4.5%;
  left: 0%;
}
.Bowl.bouncing {
  animation: bowlbounce 3s infinite;
  animation-timing-function: steps(15);
/*
        .Bowl-stone {
            animation: bowlbounce 3s infinite;
            animation-timing-function:  steps(12);
            &.s0 {
                animation: bowlbounce 4s infinite;
            animation-timing-function:  steps(12);
            }
            &.s1 {
                animation: bowlbounce 3.5s infinite;
            animation-timing-function:  steps(12);
            }
            &.s2 {
                animation: bowlbounce 5s infinite;
            animation-timing-function:  steps(12);
            }
            &.s3 {
                animation: bowlbounce 4.2s infinite;
            animation-timing-function:  steps(12);
            }
            &.s4 {
                animation: bowlbounce 3.4s infinite;
            animation-timing-function:  steps(12);
            }
        }
        */
}
.Bowl .label {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.3rem;
  color: #fff;
}
@-moz-keyframes bowlbounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@-webkit-keyframes bowlbounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@-o-keyframes bowlbounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@keyframes bowlbounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
.Button {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.Captures {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Captures .Captures-svg-background {
  position: relative;
  background-image: svg-load("../assets/misc/captures-back.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  height: 50%;
  min-height: 3rem;
  min-width: 3rem;
  max-height: 10rem;
  max-width: 10rem;
  aspect-ratio: 1/1;
}
.Captures .Captures-svg-background .Captures-stone {
  position: absolute;
  top: 0;
  left: 0;
  height: 20%;
  aspect-ratio: 1/1;
}
.Captures .Captures-svg-background .Captures-svg-foreground {
  position: absolute;
  background-image: svg-load("../assets/misc/captures-front.svg");
  background-repeat: no-repeat;
  z-index: 999;
  width: 100%;
  height: 60%;
  top: 13%;
  left: 8%;
}
.Captures .Captures-svg-background .score {
  position: absolute;
  border: 2px solid #85c442;
  color: #85c442;
  border-radius: 0.5rem;
  padding: 0.2rem;
  min-width: 4rem;
  font-size: 1.3rem;
  bottom: -2.5rem;
  right: calc(50% - 2.3rem);
  text-align: center;
  background-color: rgba(80,80,80,0.8);
  font-weight: normal;
}
.ChatDialog-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 20000;
  background-color: rgba(255,255,255,0.2);
}
.ChatDialog {
  display: inline-flex;
  padding: 0;
  width: 42.878rem;
  height: 25rem;
  max-width: 100vw;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: svg-load("../assets/misc/modal_background.svg");
  opacity: 0.9;
  color: #32529a;
  text-shadow: 0 0 2px #fff;
  font-size: 1.5rem;
  line-height: 1.7rem;
  flex-direction: column;
}
@media (orientation: portrait) {
  .ChatDialog {
    font-size: 1rem;
  }
}
.ChatDialog .ChatDialog-phrases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
  margin-left: 3rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.ChatDialog .ChatDialog-phrases .phrase {
  padding: 1rem;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  width: calc(100% - 7rem);
  text-align: center;
  cursor: pointer;
}
.ChatDialog .ChatDialog-phrases .phrase:hover,
.ChatDialog .ChatDialog-phrases .phrase:focus,
.ChatDialog .ChatDialog-phrases .phrase:active {
  background-color: #f0f0f0;
}
.ChatDialog .ChatDialog-buttons {
  flex-shrink: 0;
  height: 6rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ChatDialog .ChatDialog-buttons button {
  display: inline-block;
  width: 10rem;
  height: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0;
}
.ChatDialog .red-x,
.ChatDialog .green-check {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  opacity: 0.8;
  cursor: pointer;
}
.ChatDialog .red-x:hover,
.ChatDialog .green-check:hover {
  opacity: 1;
}
.PopupDialog-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 20000;
  background-color: rgba(255,255,255,0.2);
}
.PopupDialog {
  display: inline-flex;
  padding: 0;
  width: 34.3024rem;
  height: 20rem;
  max-width: 100vw;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: svg-load("../assets/misc/modal_background.svg");
  opacity: 0.93;
  color: #32529a;
  text-shadow: 0 0 2px #fff;
  font-size: 2.5rem;
  line-height: 2.8rem;
  flex-direction: column;
}
@media (orientation: portrait) {
  .PopupDialog {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}
.PopupDialog .PopupDialog-text {
  flex-grow: 1;
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}
@media (orientation: portrait) {
  .PopupDialog .PopupDialog-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.PopupDialog .PopupDialog-buttons {
  flex-shrink: 0;
  height: 6rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.PopupDialog .red-x,
.PopupDialog .green-check {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  opacity: 0.8;
  cursor: pointer;
}
.PopupDialog .red-x:hover,
.PopupDialog .green-check:hover {
  opacity: 1;
}
.Racoon {
  flex: 1;
  display: inline-flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
}
.Racoon .Racoon-svg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  flex: 1;
  background-image: svg-load("../assets/avatars/racoon.svg");
}
.Racoon.hover {
  animation: hover 4s infinite;
  animation: hover 3s infinite;
  animation-timing-function: ease-in-out;
}
@-moz-keyframes hover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@-webkit-keyframes hover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@-o-keyframes hover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
@keyframes hover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
.ResultsDialog-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 20000;
  background-color: rgba(255,255,255,0.2);
}
.ResultsDialog {
  display: inline-flex;
  padding: 0;
  width: 42.878rem;
  height: 25rem;
  max-width: 100vw;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: svg-load("../assets/misc/modal_background.svg");
  opacity: 0.9;
  color: #32529a;
  text-shadow: 0 0 2px #fff;
  font-size: 1.5rem;
  line-height: 1.7rem;
  flex-direction: column;
}
@media (orientation: portrait) {
  .ResultsDialog {
    font-size: 1rem;
  }
}
.ResultsDialog .ResultsDialog-results {
  flex-grow: 1;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  flex-direction: row;
  display: flex;
  align-content: stretch;
}
.ResultsDialog .ResultsDialog-results .result-text {
  display: block;
  min-height: 1.5rem;
}
.ResultsDialog .ResultsDialog-results > .black,
.ResultsDialog .ResultsDialog-results > .white {
  flex-grow: 1;
}
.ResultsDialog .ResultsDialog-results > .black .stone-avatar-container,
.ResultsDialog .ResultsDialog-results > .white .stone-avatar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.ResultsDialog .ResultsDialog-results > .black .stone-avatar-container .Avatar,
.ResultsDialog .ResultsDialog-results > .white .stone-avatar-container .Avatar {
  flex: 0;
}
.ResultsDialog .ResultsDialog-results > .black .Avatar-svg,
.ResultsDialog .ResultsDialog-results > .white .Avatar-svg {
  min-width: 6rem;
  min-height: 6rem;
  width: 6rem;
  height: 6rem;
  max-width: 6rem;
  max-height: 6rem;
}
@media (orientation: portrait) {
  .ResultsDialog .ResultsDialog-results > .black .Avatar-svg,
  .ResultsDialog .ResultsDialog-results > .white .Avatar-svg {
    min-width: 5rem;
    min-height: 5rem;
    width: 5rem;
    height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
  }
}
.ResultsDialog .ResultsDialog-results .score-stones {
  border-bottom: 2px solid;
}
.ResultsDialog .ResultsDialog-results .score-total {
  border-top: 2px solid;
}
.ResultsDialog .ResultsDialog-results .score >div {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.ResultsDialog .ResultsDialog-results .stone-svg {
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  padding-right: 0.3rem;
}
.ResultsDialog .ResultsDialog-results .label {
  display: inline-block;
  width: 10rem;
  text-align: left;
}
@media (orientation: portrait) {
  .ResultsDialog .ResultsDialog-results .label {
    width: 7rem;
  }
}
.ResultsDialog .ResultsDialog-results .value {
  display: inline-block;
  width: 2rem;
  text-align: right;
  white-space: nowrap;
}
.ResultsDialog .ResultsDialog-buttons {
  flex-shrink: 0;
  height: 6rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ResultsDialog .ResultsDialog-buttons button {
  display: inline-block;
  width: 11rem;
  height: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
  white-space: nowrap;
  margin: 0;
}
.ResultsDialog .ResultsDialog-outcome {
  flex-shrink: 0;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.ResultsDialog .ResultsDialog-outcome img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.ResultsDialog .red-x,
.ResultsDialog .green-check {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  opacity: 0.8;
  cursor: pointer;
}
.ResultsDialog .red-x:hover,
.ResultsDialog .green-check:hover {
  opacity: 1;
}
.SignInPopup {
  font-size: 1.7rem;
  line-height: 1.8rem;
}
.SignInPopup b {
  user-select: text;
}
.SignInPopup .small {
  font-size: 1rem;
}
#CharacterSelection {
  position: absolute;
  background-color: #eee;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
#CharacterSelection .NameSelection {
  background-color: rgba(120,120,120,0.9);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0rem 0.5rem rgba(120,120,120,0.9);
  padding: 1rem;
  margin-top: 4rem;
}
#CharacterSelection .NameSelection .title {
  border-bottom: 2px solid #fff;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  letter-spacing: 0.4rem;
}
#CharacterSelection .Button.check,
#CharacterSelection button.ok {
  margin: 1rem;
}
#CharacterSelection button.ok {
  font-size: 1.3rem;
}
#CharacterSelection .signin-out-buttons {
  position: fixed;
  right: 1rem;
  top: 1rem;
}
#CharacterSelection .signin-out-buttons button {
  margin-left: 1rem;
}
.ChatBubble {
  position: absolute;
  width: 12rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: rgba(255,255,255,0.8);
  padding: 0.5rem;
  font-size: 1.5rem;
  z-index: 1000;
}
.ChatBubble.opponent {
  left: 10rem;
}
@media (orientation: portrait) {
  .ChatBubble.opponent {
    left: 0;
  }
}
.ChatBubble.player {
  right: 10rem;
}
@media (orientation: portrait) {
  .ChatBubble.player {
    right: 0;
  }
}
#KidsGame {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  background-color: #333;
}
@media (orientation: landscape) {
  #KidsGame {
    flex-direction: row;
    align-content: center;
    align-items: stretch;
    justify-content: center;
  }
}
@media (orientation: portrait) {
  #KidsGame {
    flex-direction: column;
    align-content: center;
    align-items: stretch;
    justify-content: center;
  }
}
#KidsGame #opponent-container,
#KidsGame #my-container {
  flex: 1;
  min-width: 10rem;
  max-width: 100vw;
  flex-basis: 10rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
@media (orientation: landscape) and (max-width: 800px) {
  #KidsGame #opponent-container,
  #KidsGame #my-container {
    min-width: 7rem;
  }
}
@media (orientation: portrait) {
  #KidsGame #opponent-container,
  #KidsGame #my-container {
    flex-basis: 5rem;
    min-height: 5rem;
  }
}
@media (orientation: portrait) {
  #KidsGame #opponent-container,
  #KidsGame #my-container {
    flex-direction: row;
  }
}
#KidsGame #opponent-container .username,
#KidsGame #my-container .username {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
}
#KidsGame #board-container {
  flex: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (orientation: landscape) {
  #KidsGame #board-container {
    flex-basis: 100vh;
  }
}
@media (orientation: portrait) {
  #KidsGame #board-container {
    flex-basis: 100vw;
  }
}
#KidsGame #board-container .Goban {
  background-color: transparent;
}
#KidsGame #board-container .Goban > div {
  background: rgba(208,155,86,0.85) !important;
  background-color: rgba(208,155,86,0.85) !important;
}
#KidsGame .Goban-container {
  display: inline-flex;
  padding: 1.5rem;
  background: rgba(255,255,255,0.2);
  border: 2px solid #fff;
  border-radius: 0.5rem;
  border-color: rgba(255,255,255,0.5);
/*
        +portrait() {
            padding: 0;
            border: none;
            background: none;
        }
        */
}
#KidsGame .top-spacer {
  flex: 0;
  display: inline-block;
  min-height: 5rem;
  height: 5rem;
}
@media (orientation: portrait) {
  #KidsGame .top-spacer {
    display: none;
  }
}
@media (orientation: landscape) and (max-width: 800px) {
  #KidsGame .top-spacer {
    display: none;
  }
}
#KidsGame .landscape-bottom-buttons {
  flex: 0;
  display: inline-flex;
  min-height: 5rem;
  height: 5rem;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.5);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  background: rgba(255,255,255,0.2);
  min-width: 5rem;
}
@media (orientation: portrait) {
  #KidsGame .landscape-bottom-buttons {
    display: none;
  }
}
#KidsGame .portrait-bottom-buttons {
  flex: 0;
  display: inline-flex;
  min-height: 5rem;
  max-width: 90vw;
  height: 5rem;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.5);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.5rem;
  background: rgba(255,255,255,0.2);
}
@media (orientation: landscape) {
  #KidsGame .portrait-bottom-buttons {
    display: none;
  }
}
#KidsGame .portrait-bottom-buttons .left,
#KidsGame .portrait-bottom-buttons .right {
  display: inline-flex;
  flex-direction: row;
}
#KidsGame .portrait-bottom-buttons .center {
  color: #fff;
  font-weight: bold;
  overflow: hidden;
}
#KidsGame .portrait-top-spacer {
  flex: 0;
  height: 2rem;
  min-height: 2rem;
  max-height: 2rem;
}
@media (orientation: landscape) {
  #KidsGame .portrait-top-spacer {
    display: none;
  }
}
#KidsGame .Player {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
#KidsGame .Player .label {
  position: absolute;
  left: 0;
  right: 0;
  top: -1.2rem;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}
#KidsGame .results-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
}
#quit .fa,
#menu .fa {
  color: #ccc;
  font-size: 3rem;
  cursor: pointer;
}
@media (orientation: portrait) {
  #quit .fa,
  #menu .fa {
    font-size: 2rem;
  }
}
#quit .fa:hover,
#menu .fa:hover {
  color: #fff;
}
#quit {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
}
#menu {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
}
.StoneButton {
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (orientation: portrait) {
  .StoneButton {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
.StoneButton .button-text {
  max-width: 6rem;
  word-wrap: break-word;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  align-text: center;
}
@media (orientation: portrait) {
  .StoneButton .button-text {
    max-width: 4rem;
  }
}
.StoneButton .button-text.disabled {
  color: #888;
}
.AnimatedStoneCapture {
  position: fixed;
  z-index: 9999;
}
:root {
  --ROCKETWIDTH: min(20vw, 10rem);
  --ROCKETHEIGHT: calc(var(--ROCKETWIDTH) * 3);
  --FLAMEWIDTH: calc(var(--ROCKETWIDTH) / 2);
  --FLAMECENTER: calc(var(--FLAMEWIDTH) / 2);
  --FLAMEHEIGHT: calc(var(--ROCKETHEIGHT) / 2);
}
#LandingPage {
  position: absolute;
  background-color: #001533;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}
@media (orientation: portrait) {
  #LandingPage {
    flex-direction: column;
  }
}
#LandingPage .spacer {
  flex-grow: 0;
  flex-shrink: 1;
}
@media (orientation: portrait) {
  #LandingPage .spacer {
    height: calc((100vh - 100vw) / 2);
  }
}
@media (orientation: landscape) {
  #LandingPage .spacer {
    width: calc((100vw - 100vh) / 2);
  }
}
#LandingPage .mountain-background {
  flex-grow: 1;
  position: relative;
  text-align: center;
  background-image: url("/1.0/pages/home/background.svg");
  background-repeat: no-repeat;
}
#LandingPage .logo {
  position: absolute;
  text-align: center;
  background-image: url("/1.0/pages/home/kidsgoserver.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: 80%;
  height: 80%;
  left: 10%;
}
#LandingPage .learn-to-play-rocket,
#LandingPage .play-rocket {
  position: absolute;
  bottom: calc(var(--ROCKETHEIGHT) * 0.1 + 0.8rem);
  width: var(--ROCKETWIDTH);
  text-align: center;
  height: var(--ROCKETHEIGHT);
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 10;
  cursor: pointer;
}
#LandingPage .learn-to-play-rocket .flames,
#LandingPage .play-rocket .flames {
  display: none;
  z-index: 5;
  height: var(--FLAMEHEIGHT);
  width: var(--FLAMEWIDTH);
  position: absolute;
  top: var(--ROCKETHEIGHT);
  left: calc(var(--ROCKETWIDTH) / 2 - (var(--FLAMECENTER) / 2) * 1.2);
  background-image: svg-load("../assets/pages/home/flame.svg");
  background-repeat: no-repeat;
  background-position: 0 -30px;
}
#LandingPage .learn-to-play-rocket .label,
#LandingPage .play-rocket .label {
  position: relative;
  color: #fff;
  top: calc(var(--ROCKETHEIGHT) * 0.5 - 0.75rem);
}
#LandingPage .learn-to-play-rocket {
  left: 5vw;
  background-image: url("/1.0/pages/home/learn.svg");
}
#LandingPage .play-rocket {
  right: 5vw;
  background-image: url("/1.0/pages/home/play.svg");
}
#LandingPage .launch {
  transform: translateY(-100vh);
  animation: rocket-launch 1.25s;
  animation-timing-function: cubic-bezier(0.73, 0.27, 0.95, 0.66);
}
#LandingPage .launch .flames {
  display: inline-block;
}
#LandingPage .launchpad {
  position: absolute;
  display: inline-block;
  bottom: calc(var(--ROCKETHEIGHT) * 0.05);
  height: calc(var(--ROCKETHEIGHT) * 0.1);
  width: var(--ROCKETWIDTH);
  text-align: center;
  background-repeat: no-repeat;
}
#LandingPage .learn-launchpad {
  left: 5vw;
  background-image: svg-load("../assets/pages/home/launchpad.svg");
}
#LandingPage .play-launchpad {
  right: 5vw;
  background-image: svg-load("../assets/pages/home/launchpad.svg");
}
@-moz-keyframes rocket-launch {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
@-webkit-keyframes rocket-launch {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
@-o-keyframes rocket-launch {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
@keyframes rocket-launch {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
#LearnToPlay {
  position: absolute;
  background-color: #001533;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: stretch;
}
@media (orientation: portrait) {
  #LearnToPlay {
    flex-direction: column;
  }
}
#LearnToPlay .spacer {
  flex-grow: 0;
  flex-shrink: 1;
}
@media (orientation: portrait) {
  #LearnToPlay .spacer {
    height: calc((100vh - 100vw) / 2);
  }
}
@media (orientation: landscape) {
  #LearnToPlay .spacer {
    width: calc((100vw - 100vh) / 2);
  }
}
#LearnToPlay .back-background {
  position: absolute;
  display: inline-block;
  margin: auto;
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
/*
        top: 30vh;
        bottom: 30vh;
        left: 30vw;
        right: 30vw;
        */
  background-size: cover;
  background-image: url("/1.0/pages/lessons/planet.jpg") !important;
}
#LearnToPlay .background-container {
  flex-grow: 1;
  position: relative;
  text-align: center;
}
#LearnToPlay .background {
  flex-grow: 1;
  z-index: 1;
  position: absolute;
  display: inline-block;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-image: url("/1.0/pages/lessons/background.svg");
  background-repeat: no-repeat;
}
#LearnToPlay .ChapterButton {
  position: absolute;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  z-index: 1;
  background-image: svg-load("../assets/pages/lessons/button.svg");
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  opacity: 0.8;
}
#LearnToPlay .ChapterButton:hover {
  opacity: 1;
  cursor: pointer;
}
#LearnToPlay .ChapterButton.disabled {
  background-image: svg-load("../assets/pages/lessons/active.svg");
  cursor: not-allowed;
}
#LearnToPlay .ChapterButton.chapter-1 {
  left: calc(50% - 1.5rem);
  top: 12%;
}
#LearnToPlay .ChapterButton.chapter-5 {
  left: calc(50% - 1.5rem);
  bottom: 12%;
}
#LearnToPlay .ChapterButton.chapter-7 {
  top: calc(50% - 1.5rem);
  left: 12%;
}
#LearnToPlay .ChapterButton.chapter-3 {
  top: calc(50% - 1.5rem);
  right: 11%;
}
#LearnToPlay .ChapterButton.chapter-2 {
  right: 22%;
  top: 22%;
}
#LearnToPlay .ChapterButton.chapter-4 {
  right: 22%;
  bottom: 22%;
}
#LearnToPlay .ChapterButton.chapter-6 {
  left: 22%;
  bottom: 22%;
}
#LearnToPlay .ChapterButton.chapter-8 {
  left: 22%;
  top: 22%;
}
#LearnToPlay .chapter-text {
  position: absolute;
  color: #fff;
  cursor: pointer;
  user-select: none;
}
#LearnToPlay .chapter-text:hover {
  color: #ffa500;
}
#LearnToPlay .chapter-1-text {
  left: 42%;
  top: 20%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-2-text {
  right: 15%;
  top: 30%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-3-text {
  right: 3%;
  top: 55%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-4-text {
  right: 19%;
  bottom: 17%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-5-text {
  left: 43%;
  bottom: 6%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-6-text {
  left: 14%;
  bottom: 17%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-7-text {
  left: 5%;
  top: 55%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
#LearnToPlay .chapter-8-text {
  left: 15%;
  top: 30%;
  color: #fc6;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  background-color: #d4f1f4;
  padding: 5px;
  border-radius: 15px;
}
.Axol {
  background-image: svg-load("../assets/pages/lessons/axolotl.svg");
}
.big-axol-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.big-axol-container .Axol {
  flex: 1;
  background-position: center bottom !important;
}
.big-axol-container .Axol.center {
  background-position: center center !important;
}
.big-axol-container button {
  margin: 3rem;
  margin-bottom: 20vh;
  font-size: 2rem;
}
#Lesson {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}
#Lesson #Lesson-bottom-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
@media (orientation: portrait) {
  #Lesson #Lesson-bottom-container {
    flex-direction: column;
  }
}
@media (orientation: portrait) {
  #Lesson #center-container {
    order: 0;
  }
  #Lesson #right-container {
    display: none !important;
    visibility: hidden;
  }
  #Lesson #left-container {
    order: 1;
  }
}
#Lesson #right-container,
#Lesson #left-container {
  flex: 1;
  min-width: 20rem;
  max-width: 100vw;
  flex-basis: 10rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
@media (orientation: portrait) {
  #Lesson #right-container,
  #Lesson #left-container {
    flex-basis: 5rem;
    min-height: 5rem;
  }
}
@media (orientation: portrait) {
  #Lesson #right-container,
  #Lesson #left-container {
    flex-direction: row;
  }
}
#Lesson #left-container .bottom-graphic {
  flex: 0;
  display: inline-flex;
  min-height: 2rem;
  height: 2rem;
  width: calc(100% - 4rem);
  margin-right: 2rem;
  margin-left: 2rem;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.6);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  background: rgba(90,90,110,0.6);
}
@media (orientation: portrait) {
  #Lesson #left-container .bottom-graphic {
    display: none !important;
  }
}
#Lesson #axolotl,
#Lesson .Axol {
  display: inline-block;
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 30rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#Lesson #board-container {
  flex: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (orientation: landscape) {
  #Lesson #board-container {
    flex-basis: 100vh;
  }
}
@media (orientation: portrait) {
  #Lesson #board-container {
    flex-basis: 100vw;
  }
}
#Lesson #board-container .Goban {
  background-color: transparent;
}
#Lesson #board-container .Goban div {
  background: rgba(103,156,214,0.7) !important;
  background-color: rgba(103,156,214,0.7) !important;
}
#Lesson .Goban-container {
  display: inline-block;
  padding: 1.5rem;
  background: rgba(90,90,110,0.6);
  border: 2px solid #fff;
  border-radius: 0.5rem;
  border-color: rgba(255,255,255,0.6);
/*
        +portrait() {
            padding: 0;
            border: none;
            background: none;
        }
        */
}
#Lesson .top-spacer {
  flex: 0;
  display: inline-block;
  min-height: 5rem;
  height: 5rem;
}
@media (orientation: portrait) {
  #Lesson .top-spacer {
    display: none;
  }
}
@media (orientation: landscape) and (max-width: 800px) {
  #Lesson .top-spacer {
    display: none;
  }
}
#Lesson .landscape-bottom-buttons .game-button-container,
#Lesson .portrait-bottom-buttons .game-button-container {
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (orientation: portrait) {
  #Lesson .landscape-bottom-buttons .game-button-container,
  #Lesson .portrait-bottom-buttons .game-button-container {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
#Lesson .landscape-bottom-buttons .game-button-container .button-text,
#Lesson .portrait-bottom-buttons .game-button-container .button-text {
  max-width: 6rem;
  word-wrap: break-word;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  align-text: center;
}
@media (orientation: portrait) {
  #Lesson .landscape-bottom-buttons .game-button-container .button-text,
  #Lesson .portrait-bottom-buttons .game-button-container .button-text {
    max-width: 4rem;
  }
}
#Lesson .landscape-bottom-buttons {
  flex: 0;
  display: inline-flex;
  min-height: 5rem;
  height: 5rem;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.6);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  background: rgba(90,90,110,0.6);
}
@media (orientation: portrait) {
  #Lesson .landscape-bottom-buttons {
    display: none;
  }
}
#Lesson .portrait-bottom-buttons {
  flex: 0;
  display: inline-flex;
  min-height: 4rem;
  max-width: 100vw;
  width: 90vw;
  height: 4rem;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.6);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.5rem;
  background: rgba(90,90,110,0.6);
  align-content: center;
  align-items: stretch;
  justify-content: stretch;
}
@media (orientation: landscape) {
  #Lesson .portrait-bottom-buttons {
    display: none;
  }
}
#Lesson .portrait-bottom-buttons .left,
#Lesson .portrait-bottom-buttons .right {
  display: inline-flex;
  flex-direction: row;
}
#Lesson .portrait-bottom-buttons .center {
  flex: 1;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  overflow: hidden;
}
#Lesson .landscape-top-spacer {
  flex: 0;
  height: 2rem;
  min-height: 4rem;
  max-height: 4rem;
  text-align: center;
}
@media (orientation: portrait) {
  #Lesson .landscape-top-spacer {
    display: none;
  }
}
#Lesson .lesson-title {
  flex: 0;
  display: inline-flex;
  min-height: 3rem;
  min-width: 30rem;
  max-width: 80vw !important;
  display: inline-flex;
  justify-content: center;
  height: 3rem;
  padding-top: 0.5rem;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid #fff;
  border-color: rgba(255,255,255,0.6);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  background: rgba(90,90,110,0.6);
}
#Lesson .explanation-text {
  flex: 1;
  color: #fff;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background: rgba(90,90,110,0.6);
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 0px 0px 3px #000;
  padding: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 4rem;
  padding-top: 1.4rem;
  overflow: auto;
  max-height: calc(100vh - 10rem);
}
#Lesson .explanation-text p {
  margin-top: 0;
}
@media (orientation: portrait) {
  #Lesson .explanation-text {
    margin: 0;
    overflow-y: scroll;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0.5rem;
  }
}
#Lesson .Racoon {
  margin: 10%;
}
#Lesson a {
  text-decoration: none;
}
#Lesson .fade-in {
  display: inline-block;
  animation: fadeIn linear 0.5s;
}
#portrait-replay {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
@media (orientation: landscape) {
  #portrait-replay {
    display: none;
  }
}
.Axol-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.Axol-container .Axol {
  flex: 1;
  margin: 0.5rem;
  display: inline-block;
  width: 6rem;
  height: 6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ActiveGamesList {
  width: 20rem;
  max-width: 90vw;
  font-size: 1.3rem;
  text-align: left;
}
.ActiveGamesList .game {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.ActiveGamesList .game .players .player-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
}
.ActiveGamesList .game .players .player-row .Avatar {
  flex: 0;
}
.ActiveGamesList .game:hover,
.ActiveGamesList .game.active {
  background-color: #ace5ff;
  cursor: pointer;
}
.ActiveGamesList .game.disabled {
  color: #808080;
  background-color: transparent;
  cursor: not-allowed;
}
.OpponentList-container {
  display: inline-flex;
  justify-content: stretch;
  text-align: left;
  font-size: 1.2rem;
}
.OpponentList-container .OpponentList {
  width: 20rem;
  max-width: 90vw;
}
.OpponentList-container span {
  flex: 0;
  display: flex;
  align-items: center;
}
.OpponentList-container span .Avatar {
  flex: 0;
  margin-right: 1rem;
}
.OpponentList-container span .Avatar .Avatar-svg {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
}
.OpponentList-container span:hover,
.OpponentList-container span.active {
  background-color: #ace5ff;
  cursor: pointer;
}
.OpponentList-container span.disabled {
  color: #808080;
  background-color: transparent;
  cursor: not-allowed;
}
@media (orientation: portrait) {
  .OpponentList-container .ComputerOpponents {
    display: flex;
    flex-wrap: wrap;
    width: auto !important;
    max-width: 100% !important;
    padding: 0.2rem;
  }
  .OpponentList-container .ComputerOpponents h4 {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .OpponentList-container .ComputerOpponents .bot {
    margin: 0;
    padding: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: inline-flex;
    white-space: nowrap;
    min-width: 8rem;
  }
}
.OpponentList-container {
  display: inline-flex;
  justify-content: stretch;
  align-content: center;
  align-items: center;
  text-align: left;
  font-size: 1.2rem;
}
.OpponentList-container .OpponentList {
  width: 20rem;
  max-width: 90vw;
  margin: auto;
}
.OpponentList-container span {
  flex: 0;
  display: flex;
  align-items: center;
}
.OpponentList-container span .Avatar {
  flex: 0;
  margin-right: 1rem;
}
.OpponentList-container span .Avatar .Avatar-svg {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
}
.OpponentList-container span:hover,
.OpponentList-container span.active {
  background-color: #ace5ff;
  cursor: pointer;
}
.OpponentList-container span.disabled {
  color: #808080;
  background-color: transparent;
  cursor: not-allowed;
}
@media (orientation: portrait) {
  .OpponentList-container .OpponentList {
    width: 100%;
    padding: 0.2rem;
  }
  .OpponentList-container .Avatar {
    margin-right: 0.5rem !important;
  }
  .OpponentList-container h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
#Matchmaking {
  position: absolute;
  background-color: #eee;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
#Matchmaking .outer-container {
  display: flex;
  flex-direction: column;
  color: #000;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.8);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  max-height: 80vh;
  overflow: auto;
}
#Matchmaking .inner-container {
  display: inline-flex;
  flex-direction: row;
  max-height: 70vh;
  overflow: auto;
}
@media (orientation: portrait) {
  #Matchmaking .inner-container {
    flex-direction: column;
  }
}
#Matchmaking .refresh {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
}
#Matchmaking .NameSelection {
  font-size: 1.5rem;
  position: relative;
}
#Matchmaking .NameSelection .refresh {
  font-size: 1.8rem;
  position: absolute;
  left: -1rem;
  top: -0.2rem;
}
#Matchmaking .NameSelection.refreshing {
  opacity: 0.5;
}
#Matchmaking .username {
  width: 25rem;
  max-width: 100vw;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  align-content: flex-end;
}
#Matchmaking select {
  background-color: rgba(255,255,255,0.5);
}
#Matchmaking .CharacterManagement .AvatarSelection .Avatar .Avatar-svg {
  width: 15rem;
  height: 15rem;
}
#Matchmaking button.play {
  margin: 2rem;
  font-size: 2rem;
  height: 5rem;
  width: 30rem;
  max-width: 90vw;
}
@media (orientation: portrait) {
  #Matchmaking button.play {
    margin-bottom: 0;
    margin-top: 0;
  }
}
#Matchmaking .vs {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  color: #333;
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 1px;
  background-color: #333;
}
@media (orientation: portrait) {
  #Matchmaking .vs {
    flex-direction: row;
    align-items: center;
  }
}
#Matchmaking .vs .line {
  flex: 1;
  border-left: 1px solid #333;
  themed: border-color shade3;
  margin-left: 0.35em;
}
@media (orientation: portrait) {
  #Matchmaking .vs .line {
    border-top: 1px solid #333;
    margin-right: 0.35em;
  }
}
#Matchmaking .right,
#Matchmaking .left {
  display: flex;
  flex-shrink: 0;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  height: 22rem;
  min-height: 22rem;
  max-height: 22rem;
  overflow: auto;
}
@media (orientation: portrait) {
  #Matchmaking .right,
  #Matchmaking .left {
    height: auto;
    min-height: unset;
    max-height: unset;
  }
}
#Matchmaking h4 {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#Matchmaking .current-user-container {
  margin-top: 5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(255,255,255,0.8);
  padding: 1rem;
  border: 1px solid #fff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (orientation: portrait) {
  #Matchmaking .current-user-container {
    width: 100vw;
  }
}
#Matchmaking .current-user-container .Avatar {
  flex: 0;
  width: 6rem;
  height: 6rem;
  min-height: 6rem;
  max-height: 6rem;
}
#Matchmaking .current-user-container .Avatar .Avatar-svg {
  width: 6rem;
  height: 6rem;
  min-height: 6rem;
  max-height: 6rem;
}
@media (orientation: portrait) {
  #Matchmaking .current-user-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}
#Matchmaking .settings {
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  align-items: center;
  align-content: center;
}
#Matchmaking .settings .color-selector {
  margin: 0.4rem;
  display: inline-flex;
  flex-direction: column;
}
#Matchmaking .settings select {
  margin: 1rem;
  font-size: 1.4rem;
}
#Matchmaking .settings label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem;
}
#Matchmaking .settings img {
  width: 1.5rem;
  height: 1.5rem;
}
#Matchmaking .sign-in {
  margin-left: 1rem;
}
.Handicap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}
.Handicap button {
  margin: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.3rem;
}
.ReceivedChallenge {
  display: flex;
  flex-direction: row;
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;
}
.ReceivedChallenge .Avatar .Avatar-svg {
  min-width: 2.5rem;
  min-height: 2.5rem;
  height: 8rem;
  width: 8rem;
}
.ReceivedChallenge img {
  width: 1.5rem;
  height: 1.5rem;
}
#Lessons-button {
  position: absolute;
  top: 2rem;
  left: 5rem;
}
@media (orientation: portrait) {
  #Lessons-button {
    top: 1.6rem;
  }
}
@media (orientation: portrait) {
  .landscape {
    display: none;
  }
}
@media (orientation: landscape) {
  .portrait {
    display: none;
  }
}
button.primary-button {
  margin: 2rem;
  font-size: 2rem;
  background-color: #6cc489;
  border: none;
  cursor: pointer;
}
button.primary-button:hover {
  background-color: #85f2a9;
}
